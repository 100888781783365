import React, { useState } from 'react';
import ContactIcon from 'components/ContactIcon';
import emailIcon from 'img/email2.svg';
import phoneIcon from 'img/phone2.svg';
import githubIcon from 'img/github2.svg';
import linkedinIcon from 'img/linkedin2.svg';
import resumeIcon from 'img/resume2.svg';
import { Icon } from 'types';
import contact from 'data/contact.json';

export default function Contact() {
    const [hover, setHover] = useState<{ [index: string]: boolean }>({
        github: false,
        linkedin: false,
        email: false,
        resume: false,
        phone: false,
    });

    const icons: Icon[] = [
        {
            id: 'email',
            content: contact.email,
            // eslint-disable-next-line max-len
            link:`mailto:${contact.email}?subject=Contact%20me&body=Hi,%20Please%20contact%20me%20through%20this%20e-mail%20address.`,
            icon: emailIcon,
        },
        {
            id: 'phone',
            content: contact.phone,
            link: '!#',
            icon: phoneIcon,
        },
        {
            id: 'github',
            content: 'Github.com/Quanmuito',
            link: contact.github,
            icon: githubIcon,
        },
        {
            id: 'linkedin',
            content: 'Linkedin.com/in/Quanmuito110299',
            link: contact.linkedin,
            icon: linkedinIcon,
        },
        {
            id: 'resume',
            content: 'Resume',
            link: contact.resume,
            icon: resumeIcon,
        },
    ];

    const renderIcons = (): JSX.Element[] => {
        return icons.map((icon: Icon) => (
            <ContactIcon
                key={ icon.id }
                id={ icon.id }
                content={ icon.content }
                link={ icon.link }
                icon={ icon.icon }
                hover={ hover }
                setHover={ setHover }
            />
        ));
    };

    return (
        <section className="contact" id="contact">
            <div className="section-title">
                <h2>CONTACT</h2>
            </div>
            <div className="contact__container">
                { renderIcons() }
            </div>
        </section>
    );
}
