import React, { useState } from 'react';
import { Link } from 'react-scroll';

export default function Menu() {
    const [open, setOpen] = useState<boolean>(false);
    const onClick = (): void => setOpen((o) => (o = !o));

    const navList: string[] = [
        'home',
        'about',
        'experiences',
        'contact',
    ];

    const renderNav = (): JSX.Element[] => (
        navList.map((item: string) => (
            <li key={ item } className={ `menu-nav__item${open ? ' open' : ''}` }>
                <Link
                    className="menu-nav__link"
                    activeClass="active"
                    to={ item }
                    spy={ true }
                    smooth={ true }
                    offset={ 0 }
                    duration={ 1000 }>
                    { item }
                </Link>
            </li>
        ))
    );

    return (
        <header>
            <div className="menu-btn" onClick={ onClick } aria-hidden="true">
                <span className={ `menu-btn__burger${open ? ' open' : ''}` }></span>
            </div>

            <nav className={ `nav${open ? ' open' : ''}` }>
                <ul className={ `menu-nav${open ? ' open' : ''}` }>
                    { renderNav() }
                </ul>
            </nav>
        </header>
    );
}
