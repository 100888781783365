import React from 'react';

type ContactIconProps = {
	content: string
	id: string
	link: string
	icon: string
	hover: { [index: string]: boolean }
	setHover: React.Dispatch<React.SetStateAction<{ [index: string]: boolean }>>
}

export default function ContactIcon({
    content,
    id,
    link,
    icon,
    hover,
    setHover,
}: ContactIconProps) {
    const onHover = () => {
        setHover({ ...hover, [id]: !hover[id] });
    };

    return (
        <div
            className="contact__container-info"
            onMouseEnter={ onHover }
            onMouseLeave={ onHover }
            id={ id }>
            <img
                src={ icon }
                alt={ content }
                className={ `contact__container-info-icon${hover[id] ? ' open' : ''}` }
            />
            <div className={ `contact__container-info-text${hover[id] ? ' open' : ''}` }>
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    title={ content }
                    href={ link }
                >
                    { content }
                </a>
            </div>
        </div>
    );
}
