import React, { useState } from 'react';
import HomeIcon from 'components/HomeIcon';
import githubIcon from 'img/github.svg';
import linkedinIcon from 'img/linkedin.svg';
import emailIcon from 'img/email.svg';
import resumeIcon from 'img/resume.svg';
import { Icon } from 'types';
import contact from 'data/contact.json';

export default function Home() {
    const [hover, setHover] = useState<{[index:string]: boolean}>({
        github: false,
        linkedin: false,
        email: false,
        resume: false,
    });

    const icons: Icon[] = [
        {
            name: 'Github',
            id: 'github',
            link: contact.github,
            icon: githubIcon,
        },
        {
            name: 'LinkedIn',
            id: 'linkedIn',
            link: contact.linkedin,
            icon: linkedinIcon,
        },
        {
            name: 'Email',
            id: 'email',
            // eslint-disable-next-line max-len
            link: `mailto:${contact.email}?subject=Contact%20me&body=Hi,%20Please%20contact%20me%20through%20this%20e-mail%20address.`,
            icon: emailIcon,
        },
        {
            name: 'Resume',
            id: 'resume',
            link: contact.resume,
            icon: resumeIcon,
        },
    ];

    const renderIcons = (): JSX.Element[] => {
        return icons.map((icon: Icon) => (
            <HomeIcon
                key={ icon.id }
                name={ icon.name }
                id={ icon.id }
                link={ icon.link }
                icon={ icon.icon }
                hover={ hover }
                setHover={ setHover }
            />
        ));
    };

    return (
        <section className="home" id="home">
            <div className="home__name">
                <h1>
                    Quan <span className="last-name">TRAN</span>
                </h1>
                <h3>
                    <span className="last-name">Software Developer</span>
                </h3>
            </div>
            <div className="social-icons">
                { renderIcons() }
            </div>
        </section>
    );
}
