import React from 'react';
import Menu from 'components/Menu';
import Home from 'pages/Home';
import About from 'pages/About';
import Contact from 'pages/Contact';
import Experiences from 'pages/Experiences';

export default function App() {
    return (
        <div className="App">
            <Menu />
            <Home />
            <About />
            <Experiences />
            <Contact />
        </div>
    );
}
