import React from 'react';

type HomeIconProps = {
    name: string
    id: string
    link: string
    icon: string
    hover: { [index: string]: boolean }
    setHover: React.Dispatch<React.SetStateAction<{[index: string]: boolean}>>
}

export default function HomeIcon({
    name,
    id,
    link,
    icon,
    hover,
    setHover,
}: HomeIconProps) {

    const onHover = () => {
        setHover({ ...hover, [id]: !hover[id] });
    };

    return (
        <a
            title={ name }
            href={ link }
            rel="noopener noreferrer"
            target="_blank"
            className="social-icons__info"
            onMouseEnter={ onHover }
            onMouseLeave={ onHover }
            id={ id }
        >
            <img
                src={ icon }
                alt={ name }
                className={ `social-icons__info-icon${hover[id] ? ' open' : ''}` }
            />
            <div className={ `social-icons__info-title${hover[id] ? ' open' : ''}` }>
                { name }
            </div>
        </a>
    );
}
