import React from 'react';
import {
    Project as ProjectData
} from 'types';

type ProjectProps = {
    project: ProjectData
    index: number
}

export default function Project({
    project,
    index,
}: ProjectProps) {
    return (
        <div key={ project.name } className="project-detail">
            <h5>{ index + 1 }. { project.name }</h5>
            {
                project.link === undefined
                    ? <></>
                    :
                    <a
                        target="_blank"
                        className="btn btn-success btn-sm"
                        href={ project.link }
                        style={ { fontSize: '0.4rem' } }
                        rel="noreferrer"
                    >
                        Open application
                    </a>
            }


            <p><strong>DESCRIPTION:</strong> { project.description }</p>
            <p><strong>TECHNOLOGIES:</strong> { project.technologies }</p>
            {
                project.features === '' ? <></> : <p><strong>FEATURES:</strong> { project.features }</p>
            }
            {
                project.responsibility.length > 0
                    ? (
                        <>
                            <p><strong>RESPONSIBILITIES:</strong></p>
                            <ul>
                                {
                                    project.responsibility.map((line: string) => (
                                        <li key={ line }>{ line }</li>
                                    ))
                                }
                            </ul>
                        </>
                    )
                    : <></>
            }

        </div>
    );
}
