import React from 'react';
import Project from 'components/Project';
import experiences from 'data/experiences.json';
import projects from 'data/projects.json';
import {
    Project as ProjectData,
    Experience
} from 'types';

export default function Experiences() {
    const renderProjects = (experience: Experience): JSX.Element => (
        <>
            <h5>Notable projects</h5>
            {
                experience.projects.map((project: ProjectData, index: number) => (
                    <Project key={ index } project={ project } index={ index } />
                ))
            }
        </>
    );

    const renderExperience = (): JSX.Element[] => (
        experiences.map((experience: Experience) => (
            <div key={ experience.company } className="project__card">
                <div className="project__headtext"><strong>{ experience.company }</strong></div>
                <div className="project__time">{ experience.period }</div>
                <div className="project__headtext">{ experience.position }</div>
                <div className="project__description">
                    <h5>
                        { experience.description }
                    </h5>
                    {
                        experience.responsibility.map((line: string) => (
                            <p key={ line }>- { line }</p>
                        ))
                    }
                    {
                        experience.projects.length > 0
                            ? renderProjects(experience)
                            : <></>
                    }
                </div>
            </div>
        ))
    );

    return (
        <section className="experiences" id="experiences">
            <div className="section-title">EXPERIENCES & PROJECTS</div>
            <div className="container" style={ { maxWidth: '95vw' } }>
                <div className="row">
                    <div className="col-12 col-md-6 experiences__container">
                        <h2>EXPERIENCES</h2>
                        <div className="project">
                            { renderExperience() }
                        </div>
                    </div>
                    <div className="col-12 col-md-6 experiences__container">
                        <h2>PERSONAL PROJECTS</h2>
                        <div className="project">
                            <div className="project__description">
                                {
                                    projects.map((project: ProjectData, index: number) => (
                                        <Project key={ index } project={ project } index={ index } />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
