/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import data from 'data/about.json';
import { Skill } from 'types';

export default function About() {
    const renderTechnologyList = (list: string[]): JSX.Element => (
        <div className="skills__container-group-list">
            {
                list.map((line: string) => <p key={ line }>{ line }</p>)
            }
        </div>
    );

    return (
        <section className="about" id="about">
            <div className="section-title">ABOUT</div>

            <div className="about__container">
                <div className="info">
                    <div className="info__images-container">
                        <div className="info__images-container-img" id="bio1"></div>
                        <div className="info__images-container-img" id="bio2"></div>
                    </div>

                    <div className="info__description">
                        <p className="title">My profile</p>
                        {
                            data.profile.map((line: string) => (
                                <p key={ line }>{ line }</p>
                            ))
                        }
                    </div>
                </div>

                <div className="info">
                    <div className="info__description">
                        <p className="title">Some fun facts about me</p>
                        {
                            data.facts.map((line: string) => (
                                <p key={ line }>{ line }</p>
                            ))
                        }
                    </div>

                    <div className="info__images-container">
                        <div className="info__images-container-img" id="fact1"></div>
                        <div className="info__images-container-img" id="fact2"></div>
                    </div>
                </div>

                <div className="articles">
                    <h3 className="title">My published article</h3>
                    <div className="articles__list">
                        <a
                            className="articles__list-item"
                            target="_blank"
                            rel="noreferrer"
                            href="https://quizdeveloper.com/tips/the-easy-way-to-binding-event-handlers-in-reactjs-aid66"
                        >
                            <strong>React Class Component: Bind Event Handlers</strong>
                        </a>
                    </div>
                </div>

                <div className="articles">
                    <h3 className="title">My certificates</h3>
                    <div className="articles__list">
                        <a
                            className="articles__list-item"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.credly.com/badges/d01ade36-7b37-42c8-9694-19ce530617ae/linked_in_profile"
                        >
                            <strong>AWS Certified Cloud Practitioner</strong>
                        </a>
                    </div>
                </div>

                <div className="skills">
                    <p className="title">Technologies</p>
                    <div className="skills__container">
                        {
                            data.technologies.map((skill: Skill, index) => (
                                <div key={ skill.type } id={ `group-${index}` } className="skills__container-group">
                                    <h5>{ skill.type }</h5>
                                    { renderTechnologyList(skill.list) }
                                </div>

                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}
