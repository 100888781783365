import React from 'react';
import ReactDOM from 'react-dom/client';
import './sass/index.scss';
import App from 'App';
import {
    createBrowserRouter,
    RouterProvider,
    RouteObject
} from 'react-router-dom';

const routes: RouteObject[] = [
    {
        path: '/',
        element: <App />,
    },
];

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <RouterProvider router={ router } />
    </React.StrictMode>
);
